import { useSession } from 'context'
import { SIGNUP_URL } from '../routes'

export const useAuthentication = () => {

  const { user, token, expirationToken } = useSession()
  const tokenExpired = expirationToken < new Date().getTime()
  if (!user || !token || tokenExpired) {
    window.location = SIGNUP_URL
  }

}
