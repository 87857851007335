import { Panel, NextQuestionButton } from 'components'
import {ReactComponent as IconSuccess} from 'assets/icons/IconSuccess.svg'
import {translate} from 'helpers/translate'
import { right_translations } from 'langs/strings'
import { useSession } from 'context'
import { TOTAL_QUESTIONS } from 'constants.js'

export const Right = ({streak, congratulations_text, multiplier, explanation}) => {
  const { questionIndex } = useSession()

  return (
    <Panel
      icon={<IconSuccess />}
      title = { streak && streak > 1 ?
        translate(right_translations.title_strike, streak )
        :
        translate(right_translations.title )
      }
    >
      <p>
      {questionIndex === TOTAL_QUESTIONS ?
          translate(right_translations.content_last_question)
          :
        (multiplier ?
          translate(right_translations.content_multiplier, multiplier)
        :
          explanation
        )}
      </p>
      <p>{congratulations_text}</p>
      <NextQuestionButton />
    </Panel>
  )
}
