import { isMobile } from 'react-device-detect'
import { Panel, ScreenContainer, Link } from 'components'
import { ReactComponent as ArrowRightComponent } from 'assets/icons/arrow-right-white.svg'
import LukeSkywalker from 'assets/icons/luke_skywalker.png'
import {translate} from 'helpers/translate'
import { welcome_translations } from 'langs/strings'
import './Welcome.css'
import { SIGNUP_URL } from 'routes'
import { TOTAL_QUESTIONS } from '../../constants'

export const Welcome = () => {

  return (
    <ScreenContainer
      screenClassNameDefault={'welcome-screen'}
      isHeaderOnlyLogo={true}
      MainContentDefault = {
        <>
          <Panel
            title={translate(welcome_translations.title)}
          >
            <ol>
              {translate(welcome_translations.instructions, TOTAL_QUESTIONS).map((instruction, i) => {
                return <li key={i}>{instruction}</li>
                })
              }
            </ol>
            <div className="button-container">

              <Link
                href={SIGNUP_URL}
                text={translate(welcome_translations.link_text)}
                hasIconRight={true}
                iconRight={ <ArrowRightComponent />}
              />
            </div>

            {!isMobile ?
              <div className='wrapper-icon-person'>
                <img src={LukeSkywalker} alt="Luke Skywalker"/>
              </div>
              : ('')
            }

          </Panel>

        </>
      }
    />
  )
}
