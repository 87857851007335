import {isMobile} from 'react-device-detect'
import { Link,Panel, ScreenContainer } from 'components'
import './Home.css'
import Spaceship1  from 'assets/icons/Home-Spaceship-1.png'
import Spaceship2  from 'assets/icons/Home-Spaceship-2.png'
import Spaceship3  from 'assets/icons/Home-Spaceship-3.png'
import { WELCOME_URL } from 'routes'
import {translate} from 'helpers/translate'
import { home_translations } from 'langs/strings'

export const Home = () => {
  return (
    <ScreenContainer
      screenClassNameDefault={'new-game-screen'}
      isHeaderOnlyLogo={true}
      MainContentDefault= {
        <>
          <Panel
            panelClassName={'panel-color panel-home'}>

            <p>{translate(home_translations.content.text_1)}</p>
            <p>{translate(home_translations.content.text_2)}  <b>{translate(home_translations.content.text_3)}</b></p>
            <p className="note">{translate(home_translations.content.disclaimer)}</p>

            <div className="button-container">

              <Link
                href={WELCOME_URL}
                text={translate(home_translations.link_text)}
              />
            </div>

          </Panel>
          <div className='wrapper-icon-machine'>
              {!isMobile ? <img className='spaceship1' src={Spaceship1} alt=""/> : ('') }
              <img className='spaceship2' src={Spaceship2} alt=""/>
              <img className='spaceship3' src={Spaceship3} alt=""/>
          </div>
        </>
      }
    />
  )
}
