import './Timer.css'
import { CircularProgress } from 'components'
import { TOTAL_QUESTIONS,
  QUESTION_EXPIRATION_TIME} from 'constants.js'

export const Timer = ({ minutes = 0, seconds = 0, index, points }) => {

  return(
    <>


      <div className="timer">
        <p className="timer_item timer__questions">Pregunta {index}/{TOTAL_QUESTIONS}</p>

        <div className="timer_item timer__icon-container">
          <div className='timer__wrapper'>
            <CircularProgress
              size={250}
              strokeWidth={QUESTION_EXPIRATION_TIME}
              percentage={(seconds/QUESTION_EXPIRATION_TIME)*100}
              colorPercentage="#FF4745"
              colorDefault="#FFFFFF"
            />
          </div>


          <div className="timer_item timer__info">
            <span>
              {minutes.toString().padStart(2, '0')}:
              {seconds.toString().padStart(2, '0')}
            </span>
          </div>
        </div>
        <p className="timer__points">{points} puntos</p>
      </div>
    </>
  )
}
