import './MainContent.css'
export const MainContent = (props) => {
  return (
    <article className={props.isContentLayoutMulti? 'article-background' : 'article-default'}>

      { props.isContentLayoutMulti ?
        <div className={`wrapper-main-content ${props.screenClassName}`}>

          <div className="item main-content-first">
            {props.MainContentFirst}
          </div>
          <div className="item main-content-second">
            {props.MainContentSecond}
          </div>
          <div className="item main-content-third">
            {props.MainContentThird}
          </div>
        </div>
        :
        <div className={`wrapper-main-content ${props.screenClassNameDefault}`}>
          {props.MainContentDefault}
        </div>
      }
    </article>
  )
}
