import { createContext, useState, useContext } from 'react'
import { TOKEN_EXPIRATION_TIME } from 'constants.js'
const SessionContext = createContext()
const questionImages = []

const SessionProvider = ({ children }) => {
  const [score, setScore] = useState(0)
  const [user, setUser] = useState()
  const [token, _setToken] = useState()
  const [expirationToken, _setExpirationToken] = useState()
  const [questionIndex, setQuestionIndex] = useState(0)
  const [secondsRemaining, setSecondsRemaining] = useState(0)
  const [currentQuestionImage, setCurrentQuestionImage] = useState()

  // * EXPIRATION DATE IS SET ON TOKEN SET.
  // * TOKEN_EXPIRATION_TIME MINUTES AFTER TOKEN IS SET.
  const setToken = token => {
    const date = new Date()
    date.setMinutes(date.getMinutes() + TOKEN_EXPIRATION_TIME)
    _setExpirationToken(date.getTime())
    _setToken(token)
    setQuestionIndex(0)
    setScore(0)
  }

  const setSeconds = (seconds) => {
    setSecondsRemaining(seconds)
  }

  const changeCurrentQuestionImage = () => {
    if (questionImages.length === 0) {
      questionImages.length = 15
      questionImages.fill(null)
      questionImages.forEach((v,i) => questionImages[i] = i + 1)
    }

    const n = Math.floor(Math.random() * questionImages.length)
    const currentImage = questionImages.splice(n, 1)

    setCurrentQuestionImage(currentImage[0])
  }

  return (
    <SessionContext.Provider
      value={{
        changeCurrentQuestionImage,
        currentQuestionImage,
        expirationToken,
        questionIndex,
        score,
        secondsRemaining,
        setQuestionIndex,
        setScore,
        setSeconds,
        setToken,
        setUser,
        token,
        user,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export const useSession = () => useContext(SessionContext)

export default SessionProvider
