import { Panel,NextQuestionButton } from 'components'
import { ReactComponent as IconTimeOut } from 'assets/icons/IconTimeOut.svg'
import { time_out_translations } from 'langs/strings'
import {translate} from 'helpers/translate'
import { useSession } from 'context'
import { TOTAL_QUESTIONS } from 'constants.js'

export const TimeOut = () => {
  const { questionIndex } = useSession()

  return (
    <Panel
      title={translate(time_out_translations.title)}
      icon={<IconTimeOut />}
    >
      {questionIndex === TOTAL_QUESTIONS ?
        <p>{translate(time_out_translations.content_last_question)}</p>
      :
      translate(time_out_translations.content).map((item, i) =>
        <p key={i}>{item}</p>
      )
      }

      <div className='button-container'>
        <NextQuestionButton />
      </div>
    </Panel>
  )
}
