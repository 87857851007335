import classNames from 'classnames'
import './Panel.css'

export const Panel = ({ children, panelClassName, style, title, icon }) => (
  <div
    className={classNames('panel', panelClassName)}
    style={style}
  >
    {title ? (
      <h2 className={'main-subtitle'}>{title}</h2>)
      : ('')
    }
    {icon ? (
      <div className={'panel__icon'}>{icon}</div>)
      : ('')
    }
    {children}
  </div>
)
