import { useEffect, useState } from 'react'
import { useQuery, useQueryCache, useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useTimer } from 'react-timer-hook'
import { fetchQuestion as _fetchQuestion, postAnswer as _postAnswer } from 'api'
import {
  AnswersList,
  AnswerWaitScreen,
  Timer,
  ScreenContainer,
} from 'components'
import {ReactComponent as Spinner} from 'assets/icons/Spinner.svg'
import { useSession } from 'context'
import { resetGame, secondsFromNow } from 'helpers'
import { useAuthentication } from 'hooks'
import './Game.css'
import { QUESTION_EXPIRATION_TIME, ANSWER_WAIT_TIME } from 'constants.js'
import {ANSWER_URL, BASE_URL} from 'routes'
import {game_translations} from 'langs/strings'
import {translate} from 'helpers/translate'

export const Game = () => {
  useAuthentication()
  const { questionIndex, setSeconds, setScore, token, score, changeCurrentQuestionImage, currentQuestionImage, setQuestionIndex } = useSession()
  const queryCache = useQueryCache()
  const history = useHistory()
  const [isProcessing, setIsProcessing] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  // * INIT TIMER
  const {
    pause: pauseTimer,
    resume: resumeTimer,
    seconds: secondsRemaining,
  } = useTimer({
    expiryTimestamp: secondsFromNow(QUESTION_EXPIRATION_TIME),
    onExpire: () => {
      postAnswer('')
    },
  })

  useEffect(() => {
    changeCurrentQuestionImage()
    pauseTimer()
    setSeconds(0)
  }, [])


  // * FETCH QUESTION
  const {
    data: question,
    isError: isErrorQuestion,
    isLoading: isQuestionLoading,
    isFetching: isQuestionFetching,
  } = useQuery('fetchQuestion',
    async () => await _fetchQuestion({ token }),
    {
      onError: () => resetGame(),
      onSuccess: () => {
        setQuestionIndex(index => index + 1)
        setIsImageLoaded(true)
        resumeTimer()
      },
    })

  const isLoadingQuestion = isQuestionLoading || isQuestionFetching

  // * PROCESS ANSWER_URL RESULT
  const processAnswerResult = ({
    total_points: score,
  }) => {
    if (typeof score === 'number') {
      setScore(score)
    }

    return history.push(ANSWER_URL)
  }

  // * GIVE ANSWER_URL
  const [postAnswer, { isError: isErrorAnswer }] = useMutation(
    async answerId => await _postAnswer({ answerId, token }),
    {
      onError: () => resetGame(),
      onMutate: () => setIsProcessing(true),
      onSuccess: data => {
        queryCache.setQueryData('postAnswer', data)
        setTimeout(() => processAnswerResult(data), ANSWER_WAIT_TIME)
      },
    },
  )

  useEffect(() => {
    if (isErrorQuestion || isErrorAnswer) resetGame()
  }, [isErrorQuestion, isErrorAnswer])

  return (
    <>
      {!isImageLoaded ? (
        <ScreenContainer
          isHeaderLayoutMulti={true}
          headerClassName={'header_translations-game'}
          contentHasBackground={true}
          isContentLayoutMulti={false}
          screenClassName={'game-screen'}
          screenClassNameDefault={'wrapper-spinner'}
          MainContentDefault = {
            <div className='modal-loading'>
              <Spinner/>
            </div>
          }
        />

      ) : (
        <ScreenContainer
          isHeaderLayoutMulti={true}
          headerClassName={!isLoadingQuestion ? 'header-game header-game-default' : ''}
          contentHasBackground={!isLoadingQuestion}
          isContentLayoutMulti={!isLoadingQuestion}
          screenClassName={!isLoadingQuestion ? 'game-screen game-screen-default' : ''}
          screenClassNameDefault={'wrapper-spinner'}
          MainContentFirst={
            <>
              {question?.text ?
                <div className='wrapper-timer'>
                  <Timer
                    index={questionIndex}
                    seconds={secondsRemaining}
                    points={score} />
                </div>: ('')}

            </>
          }
          MainContentSecond={
            <>
              {!isLoadingQuestion ?

                (
                  <>
                    <h2 className={`main-subtitle ${isProcessing ? 'processing-main-subtitle' : ''}`}>
                      {question.text}</h2>

                    {isProcessing ?
                      <AnswerWaitScreen tip={question.tip}  />
                      :
                      <>
                        { question?.answers && (
                          <div className='wrapper-main-content-second'>
                            <AnswersList
                              answers={question.answers}
                              onSelect={id => {
                                pauseTimer()
                                setSeconds(secondsRemaining)
                                postAnswer(id)}}
                            />
                          </div>
                        )}
                      </>
                    }
                  </>
                ) : (
                  <div className={'wrapper-default'}>
                    <Spinner />
                  </div>
                )
              }

            </>
          }

          MainContentThird = {
            <>
              {!isLoadingQuestion && (
                <>
                  <h2 className="main-subtitle">{translate(game_translations.main_subtitle)}</h2>
                  <div className='wrapper-image'>

                    <img
                      className="image-responsive"
                        src={`${BASE_URL}/static/media/questions/${currentQuestionImage}.png`}
                      alt="" />
                  </div>
                </>
              )}
            </>

          }

        />
      )}
    </>

  )
}
