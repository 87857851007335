import {
    Button,
    Panel,
    ScreenContainer
} from 'components'
import './Finish.css'
import { isMobile } from 'react-device-detect'
import AhsokaTano from 'assets/icons/Ahsoka_Tano.png'
import { useAuthentication } from '../../hooks'
import { header_translations, finish_translations } from 'langs/strings'
import { RANKING_URL, SIGNUP_URL } from 'routes'
import {translate} from 'helpers/translate'
import { useSession } from 'context'

export const Finish = () => {
  useAuthentication()
  const { score } = useSession()

  return (
    <ScreenContainer
      screenClassNameDefault={'finish'}
      isHeaderLayoutMulti={true}
      hasNoTitle={true}
      isContentLayoutMulti={false}
      isHeaderWithLink={false}
      headerButtonClass={'button button--link'}
      headerButtonText={translate(header_translations.ranking_link)}
      headerPathButton={RANKING_URL}
      MainContentDefault={
        <>
          <Panel
            title={translate(finish_translations.title, (score || '0'))}
          >

            {translate(finish_translations.content).map((item, i) =>
              <p key={i}>
                {item.text}
              </p>
            )}
            {!isMobile ?
              <div className='wrapper-icon-finish'>
                  <img src={AhsokaTano} alt="Ahsoka Tano"/>
              </div>
              : ('')
            }
              <div className='wrapper-buttons'>
                  <Button
                      pathButton={SIGNUP_URL}
                      text={'Volver a jugar'}/>
                  <Button
                      className='ranking button'
                      pathButton={RANKING_URL}
                      text='Ver el ranking'/>
              </div>

          </Panel>
        </>
      }
    />
  )
}
