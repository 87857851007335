import React, { useEffect, useState } from 'react'

export const CircularProgress = ({ size, strokeWidth, percentage, colorPercentage, colorDefault, }) => {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    setProgress(percentage)
  }, [percentage])

  const viewBox = `0 0 ${size} ${size}`
  const radius = (size - strokeWidth) / 2
  const circumference = radius * Math.PI * 2
  const dash = (progress * circumference) / 100

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        fill="white"
        stroke={colorPercentage}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        fill="none"
        stroke={colorDefault}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth + 10}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeDasharray={[circumference - dash, dash]}
      />

    </svg>
  )
}

