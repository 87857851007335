import { Panel, NextQuestionButton } from 'components'
import {ReactComponent as IconError} from 'assets/icons/IconError.svg'
import { wrong_translations } from 'langs/strings'
import {translate} from 'helpers/translate'

export const Wrong = ({text}) => {

  return (
    <Panel
      title={translate(wrong_translations.title)}
      icon={<IconError />}
    >
      <p>{text}</p>
      <p>
        <NextQuestionButton />
      </p>
    </Panel>
  )
}
