export default ({ email, username }) =>
  fetch(`${process.env.REACT_APP_API_ROOT}/users`, {
    body: JSON.stringify({ email, username }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(response => {
        return !response.ok
                ? Promise.reject(response)
                : response.json()
        }
    )
