const ScoresListItem = ({ username, total_points, index }) => (
  <li className="ranking-item">
    <div className="item__position">{index}.</div>
    <div className="item__name">
      {username}
    </div>
    <div className="item__points">{total_points}</div>
  </li>
)

export const ScoresList = ({ scores }) => (
  <ol className="ranking-list">
    {scores.map((score, i) => (
      <ScoresListItem key={i} index={i + 1} {...score} />
    ))}
  </ol>
)
