import { Button } from 'components/index'
import { useSession } from 'context'
import { ReactComponent as ArrowRightComponent } from '../../../assets/icons/arrow-right-white.svg'
import { TOTAL_QUESTIONS } from 'constants.js'
import { FINISH_URL, GAME_URL} from '../../../routes'

export const NextQuestionButton = () => {
  const { questionIndex } = useSession()
  return (
    <Button
      pathButton={ questionIndex === TOTAL_QUESTIONS ?  FINISH_URL : GAME_URL }
      text={questionIndex === TOTAL_QUESTIONS  ? 'Fin de la partida' : 'Siguiente pregunta'}
      hasIconRight={true}
      iconRight={ <ArrowRightComponent />}/>
  )

}
