import './Button.css'
import { useHistory } from 'react-router-dom'

export const Button =  (props) => {
  const {
    type,
    className,
    disabled,
    text,
    hasIconRight,
    iconRight,
    hasIconLeft,
    iconLeft
  } = props
  const history = useHistory()
  const handleClickGoTo=() => history.push( props.pathButton)
  return (
    <button type={type ? type : 'button'}
      className={className ? className : 'button button--primary'}
      disabled={disabled}
      onClick={props.pathButton &&
        (() => handleClickGoTo(props.pathButton))
      }>

      {hasIconLeft ? (
        <span className={'button__icon'}>
          {iconLeft}
        </span>) : ('')
      }

      <span className={'button__text'}>{text}</span>


      {hasIconRight ? (
        <span className={'button__icon'}>
          {iconRight}
        </span>) : ('')
      }

    </button>
  )

}
