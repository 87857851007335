import './Link.css'
export const Link = (props) => {
  const {
    href,
    className,
    text,
    hasIconRight,
    iconRight,
    hasIconLeft,
    iconLeft,
    type
  } = props
  return (
    <a href={href}
      className={type === 'link' ?`link ${className}` : 'button button--primary'}
    >

      {hasIconLeft ? (
        <span className={'button__icon'}>
          {iconLeft}
        </span>) : ('')
      }

      <span className={'button__text'}>{text}</span>


      {hasIconRight ? (
        <span className={'button__icon right'}>
          {iconRight}
        </span>) : ('')
      }
    </a>
  )
}

