export const translate = (file, prop) => {
  const replaceString = (string) => string.replace(/{{.*}}/, prop)

  if (Array.isArray(file)) {
    const isObject = file.some((item) => typeof item === 'object' && item !== null)

    if (isObject) {
      // console.log(isObject)

     return file.map((item) => {

       for (const [ value ] of Object.entries(item)) {
         // do something with `key` and `value`
         if(prop) {
           item[value] = replaceString(value)
         }
       }
       // console.log(item)
       return item

     })

    } else {
      return file.map((item) =>  prop ? replaceString(item) : item)
    }
  } else {
    return  prop ? replaceString(file) : file
  }
}
// import { welcome_translations } from '../langs/strings'
// console.log(translate(welcome_translations.ranking))
