import { useQuery } from 'react-query'
import { getRanking } from 'api'
import {
    ScoresList,
    ScreenContainer,
    Panel, Button
} from 'components'
import { ReactComponent as Spinner } from 'assets/icons/Spinner.svg'
import DarthMaul from 'assets/icons/Darth-Maul.png'
import { isMobile } from 'react-device-detect'
import './Ranking.css'
import { useSession } from 'context'
import {TOTAL_QUESTIONS} from 'constants.js'
import { GAME_URL, SIGNUP_URL } from 'routes'
import { header_translations } from 'langs/strings'
import {translate} from 'helpers/translate'


export const Ranking = () => {
  const { questionIndex } = useSession()
  const { data: scores, isLoading } = useQuery(
    'ranking',
    async () => await getRanking(),
  )

  return (
    <ScreenContainer
      screenClassNameDefault={'ranking-screen'}
      headerWithoutTitle={true}
      headerWithLink={true}
      isHeaderLayoutMulti={true}
      isHeaderWithLink={questionIndex !== TOTAL_QUESTIONS }
      hasHeaderSecondTitle={true}
      headerSecondTitle={translate(header_translations.ranking_second_title)}
      headerButtonClass={'button button--link'}
      headerButtonText={translate(header_translations.go_back_link)}
      headerPathButton={GAME_URL}
      MainContentDefault={
        <>
          {isLoading ?
            <div className={'wrapper-spinner'}>
              <Spinner />
            </div>
            : (
              <div>
                  <Panel>

                    {scores && <ScoresList scores={scores} />}
                    {!isMobile ?
                      <div className='wrapper-icon-ranking'>
                          <img src={DarthMaul} alt="Darth Maul"/>
                      </div>
                      : ('')
                    }
                  </Panel>
                  <div className='wrapper-buttons'>
                      <Button
                      pathButton={SIGNUP_URL}
                      text={'Volver a jugar'}/>
                  </div>
              </div>
            )
          }
        </>
      }
    />
  )
}
