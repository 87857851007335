export default ({ answerId, token }) =>
  fetch(`${process.env.REACT_APP_API_ROOT}/questions/submit`, {
    body: JSON.stringify({ answer_id: answerId }),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => res.json())
