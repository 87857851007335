import { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { signup } from 'api'
import { isMobile } from 'react-device-detect'
import {
  Button,
  InputField,
  Panel,
  ScreenContainer,
} from 'components'
import {ReactComponent as Spinner} from 'assets/icons/Spinner.svg'
import { ReactComponent as ArrowRightComponent } from 'assets/icons/arrow-right-white.svg'
import chewbacca from 'assets/icons/chewbacca.png'
import { useSession } from 'context'
import './Signup.css'
import { GAME_URL, RANKING_URL } from 'routes'
import {translate} from 'helpers/translate'
import {validationsForms,
        sign_up_translations
} from 'langs/strings'

export const Signup = () => {
  const history = useHistory()
  const nameRef = useRef()
  const emailRef = useRef()
  const { setUser, setToken } = useSession()
  const [isLoadingGame, setIsLoadingGame] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isUserRegistered, setIsUserRegistered] = useState()
  const [signupError, setSignupError] = useState(false)
  const [user3Times, setUser3Times] = useState(false)
  const [isActive, setIsActive] = useState({
    email: false,
    name: false
  })

  const [isFormValid, setIsFormValid] = useState({
    email: false,
    name: false
  })

  const [isDirty, setIsDirty] = useState({
    email: false,
    name: false
  })

  // *2. SIGNUP_URL SUCCESS
  const onSignupSuccess = ({ code, name }) => {
    setUser({ code, name })
    setToken(code)
    history.push(GAME_URL)
  }

  // *1. REQUEST SIGNUP_URL
  const postSignup = async ({ email, username }) => {
    setIsLoading(true)
    await signup({ email, username })
      .then(data => {
        if(data?.detail) {
          setIsUserRegistered(true)
          setIsLoading(false)
        }
        else {
          setIsUserRegistered(false)
          setIsLoadingGame(true)
          onSignupSuccess({code: data.code, name: data.username} )
        }
      })
        .catch( (err) => {
          setIsLoading(false)
          if (err.status === 403) {
            setUser3Times(true)
          } else {
            setSignupError(true)
          }
        })
  }

  const validateForm  = (userEmail)=> {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@paradigmadigital.com$/
    return  regex.test(String(userEmail).toLowerCase())
  }

  const handleOnSubmit = (event) => {
    event.preventDefault()
    const username = nameRef.current.value
    const email = emailRef.current.value
    postSignup({ email, username })

  }

  const handleChange = e => {

    if(isDirty[e.target.name]  === false) {
      setIsDirty({...isDirty, [e.target.name]: true})
    }

    if (e.target.type === 'email') {
      const isValid = validateForm(e.target.value)
      setIsFormValid(({...isFormValid, [e.target.name]: isValid}))
    }
    else {
      setIsFormValid(({...isFormValid, [e.target.name]: !!e.target.value }))
    }
    setIsActive(({...isActive, [e.target.name]: !!e.target.value }))
  }
  const getInputError = (target) => {

    if(!target?.current?.value) {
      if(isDirty[target?.current?.name]) {
        return validationsForms.required
      }
    }
    else {
      if(target?.current?.type === 'email' && !isFormValid.email) {
        return validationsForms.email
      }
    }


  }
  return (
    <ScreenContainer
      screenClassNameDefault={'sign-up-screen'}
      isHeaderOnlyLogo={true}
      MainContentDefault = {
        <>
          {isLoading && (
            <div className='modal-loading'>
              <Spinner/>
            </div>
          )}

          {!isLoadingGame &&
          <Panel
            title={ !user3Times ? translate(sign_up_translations.title) : translate(sign_up_translations.title3Times) }
          >
            {!user3Times
              ? <form
                  className={'form'}
                  onSubmit={handleOnSubmit} noValidate>
                {isUserRegistered && <p className={'highlighted'}>{validationsForms.isUserRegistered}</p>}
                <InputField
                    ref={nameRef}
                    error={getInputError(nameRef)}
                    label={sign_up_translations.label_username}
                    htmlFor='name'
                    wrapperClassName={isActive.name ? 'wrapper-input-active' : ''}
                >
                  <input
                      name='name'
                      className={'input-form'}
                      type="text"
                      required
                      onChange={(e) => handleChange(e)}
                  />
                </InputField>

                <InputField
                    ref={emailRef}
                    error={getInputError(emailRef)}
                    label={sign_up_translations.label_email}
                    htmlFor='email'
                    wrapperClassName={isActive.email ? 'wrapper-input-active' : ''}
                >
                  <input
                      name='email'
                      type="email"
                      required
                      className={'input-form'}
                      onChange={(e) => handleChange(e)}/>
                </InputField>
                {signupError ?
                    <p>{sign_up_translations.error}</p>
                    : ('')
                }

                <div className='button-container'>
                  <Button
                      text={sign_up_translations.button_text}
                      type={'submit'}
                      hasIconRight={true}
                      disabled={!(isFormValid.name && isFormValid.email)}
                      iconRight={<ArrowRightComponent/>}
                  />
                </div>

              </form>
              : <div className='button-container'>
                  <Button
                      pathButton={RANKING_URL}
                      text={'Ver el ranking'}/>
                </div>
            }
            {!isMobile ?
              <div className='wrapper-icon'>
                <img src={chewbacca} alt="Chewbacca"/>
              </div>
              : ('')
            }
          </Panel>
          }
        </>
      }
    />
  )
}
