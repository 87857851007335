import { TOTAL_QUESTIONS } from 'constants.js'

export default () =>
  fetch(
    `${process.env.REACT_APP_API_ROOT}/users/classification?limit=${TOTAL_QUESTIONS}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  ).then(res => res.json())
