import './AnswersList.css'

const AnswerCard = ({ id, text, onClick }) => (
  <li onClick={() => onClick(id)} className="answer-card-list__item">
    <p>{text}</p>
  </li>
)


export const AnswersList = ({ answers, onSelect }) => (
  <ul className="answer-card-list">
    {answers.map((answer, i) => (
      <AnswerCard
        key={i}
        index={i + 1}
        {...answer}
        onClick={id => onSelect(id)}
      />
    ))}
  </ul>
)
