import './ScreenContainer.css'
import { Header } from 'components/shared/Header/Header'
import { MainContent } from '../shared/MainContent/MainContent'


export const ScreenContainer  = (props) => {
  return (
    <>
      <Header
        isHeaderOnlyLogo={props.isHeaderOnlyLogo}
        isHeaderLayoutMulti={props.isHeaderLayoutMulti}
        hasNoTitle={props.hasNoTitle}
        headerClassName={props.headerClassName}
        isHeaderWithLink={props.isHeaderWithLink}
        hasHeaderSecondTitle={props.hasHeaderSecondTitle}
        headerSecondTitle={props.headerSecondTitle}
        headerPreSecondTitle={props.headerPreSecondTitle}
        headerButtonClass={props.headerButtonClass}
        headerPathButton={props.headerPathButton}
        headerButtonText={props.headerButtonText}
        handleClickHeaderButton={props.handleClickHeaderButton}
        pathButton={props.pathButton}
      />

      <MainContent
        screenClassName={props.screenClassName}
        screenClassNameDefault={props.screenClassNameDefault}
        contentHasBackground={props.contentHasBackground}
        isContentLayoutMulti={props.isContentLayoutMulti}
        MainContentFirst={props.MainContentFirst}
        MainContentSecond={props.MainContentSecond}
        MainContentThird={props.MainContentThird}
        MainContentDefault={props.MainContentDefault}
      />
    </>
  )

}

/**
 * header_translations, main + child
 */
