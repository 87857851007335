import { Panel } from 'components'
import {ReactComponent as Spinner} from 'assets/icons/Spinner.svg'
import './AnswerWaitScreen.css'
import { answer_wait_screen_translations } from '../../langs/strings'
import { translate } from 'helpers/translate'


export const AnswerWaitScreen = ({ tip }) => (
  <Panel
    panelClassName={'panel-answer-wait-screen'}
    title={translate(answer_wait_screen_translations.title)}
    icon={<Spinner />}
  >
    <div className="panel-container">
      <p className="panel-text">{tip}</p>
    </div>
  </Panel>
)
