import { useQueryCache } from 'react-query'
import { useSession } from 'context'
import {
  ScreenContainer,
  Timer,
} from 'components'
import { Right } from './Types/Right'
import { Wrong } from './Types/Wrong'
import { TimeOut } from './Types/TimeOut'
import  ImageTimeOut from 'assets/images/timeout_img.png'
import { useAuthentication } from '../../hooks'
import { BASE_URL } from '../../routes'


export const Answer = () => {
  useAuthentication()
  const queryCache = useQueryCache()
  const { questionIndex, secondsRemaining, score, currentQuestionImage } = useSession()
  const { achievement } = queryCache.getQueryData('postAnswer')
  const { explanation } = queryCache.getQueryData('postAnswer')
  const { is_correct } = queryCache.getQueryData('postAnswer')

  return (
    <>
      <ScreenContainer

        screenClassName={'answers-screen game-screen-default'}
        headerClassName={'header-answers header-game-default'}
        isHeaderLayoutMulti={true}
        isContentLayoutMulti={true}
        contentHasBackground={true}
        MainContentFirst={
          <>
            <div className='wrapper-timer'>
              <Timer
                index={questionIndex}
                seconds={secondsRemaining}
                points={score}
              />
            </div>
          </>
        }
        MainContentSecond={
          <>
            {secondsRemaining === 0 ?
              (
                <TimeOut />
              )
              : is_correct ? (
                  <Right
                    streak={achievement ? achievement.streak : 0}
                    multiplier={achievement && achievement.multiplier}
                    explanation={explanation}
                    congratulations_text={achievement ? achievement.congratulations_text : ''} />
                )
                  : (
                    <Wrong text={ explanation } />
                  )
            }
          </>
        }
        MainContentThird = {
          <>
            <div className='wrapper-image'>
              {secondsRemaining === 0 ? (
                <img className={'image-responsive'}
                  src={ImageTimeOut}
                  alt={ImageTimeOut} />
              )
                : (
                  <img className={'image-responsive'}
                    src={`${BASE_URL}/static/media/questions/${currentQuestionImage}.png`}
                    alt="" />
                )
              }
            </div>
          </>

        }
      />

    </>
  )
}
