import { Children, cloneElement, forwardRef } from 'react'


export const InputField = forwardRef((
  {
    children,
    error,
    label,
    wrapperClassName,
    labelClassName,
    htmlFor
  }, ref) => (

  <div className='form-group'>

    <div className={`wrapper-input ${wrapperClassName}`}>

      {Children.map(children, child => cloneElement(child, { ref }))}
      <label className={labelClassName} htmlFor={htmlFor}>
        <span>{label}</span>
      </label>
    </div>
    {error ? (<div className={'error-message'}>{error}</div>) : ('')}


  </div>
))
