// import {isMobile} from 'react-device-detect'
import { Logo } from 'components/shared/Logo/Logo'
import './header.css'
import { isMobile } from 'react-device-detect'
import { Button } from '../Button/Button'
import {header_translations} from 'langs/strings'
import {translate} from 'helpers/translate'

export const Header = (props) => {

  const LogoBlock = (
    <div className='wrapper-logo'>
      <Logo />
    </div>
  )

  const mainTitleBlock =  (
    <div className={`wrapper-main-title ${props.hasHeaderSecondTitle ? 'header-second-title' : ''}`}>
      {props.hasHeaderSecondTitle && !isMobile ?
        <p className={'pre-second-title'}>{props.headerPreSecondTitle}</p> : ''}
      <h1 className='main-title'>
        {props.hasHeaderSecondTitle ? props.headerSecondTitle : translate(header_translations.main_title)}
        <span className={'highlighted'}>.</span>
      </h1>
    </div>
  )

  return (
    <>
      {props.isHeaderOnlyLogo ?
        <header className={`header header--only-logo ${props.headerClassName}`}>
          {LogoBlock} {props.hasNoTitle ? '' : mainTitleBlock}
        </header>
        : ('')
      }
      {props.isHeaderLayoutMulti ?
        <header className={`header ${props.headerClassName} header--multi${props.isHeaderWithLink ? ' header--multi--link' : ''} ${props.hasNoTitle ? 'header--no--title' : ''}`}>
          {LogoBlock}
          {props.hasNoTitle ? '' : mainTitleBlock}
          {props.isHeaderWithLink ?
            <div className='wrapper-link'>
              <Button
                className={props.headerButtonClass}
                pathButton={props.headerPathButton}
                text={props.headerButtonText}
              />
            </div>
            : ('')
          }
        </header>
        : ('')}
    </>
  )
}

