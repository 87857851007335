import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { ReactQueryConfigProvider } from 'react-query'
import { SessionProvider } from 'context'
import {
  Game,
  Finish,
  Home,
  Ranking,
  // Restart,
  Signup,
  Welcome,
  Answer
} from 'pages'
import reactQueryConfig from './reactQueryConfig.json'
import 'styles/_base.css'
import  * as ROUTES from './routes'

export default () => (
  <SessionProvider>
    <ReactQueryConfigProvider config={reactQueryConfig}>
      <Router>
        {/* prettier-ignore */}
        <Switch>
          {/* ! Render with key to force re-mount of component */}
          <Route path={ROUTES.HOME_URL} exact render={() => <Home key={Date.now()} />} />
          <Route path={ROUTES.WELCOME_URL} render={() => <Welcome key={Date.now()} />} />
          <Route path={ROUTES.SIGNUP_URL} render={() => <Signup key={Date.now()} />} />
          <Route path={ROUTES.GAME_URL} render={() => <Game key={Date.now()} />} />
          <Route path={ROUTES.ANSWER_URL} render={() => <Answer key={Date.now()} />} />
          <Route path={ROUTES.RANKING_URL} render={() => <Ranking key={Date.now()} />} />
          <Route path={ROUTES.FINISH_URL} render={() => <Finish key={Date.now()} />} />
          <Redirect from="*" to="/" />
          {/*<Route path={ROUTES.RESTART_URL} render={() => <Restart key={Date.now()} />} />*/}
        </Switch>
      </Router>
    </ReactQueryConfigProvider>
  </SessionProvider>
)
