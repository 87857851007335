export const validationsForms = {
  required: 'Campo obligatorio',
  email: 'Formato incorrecto de Email',
  isUserRegistered: 'Usuario ya registrado'
}
export const header_translations = {
  main_title: 'El despertar de la fuerza',
  ranking_link: 'Ver el ránking',
  go_back_link: 'Volver al juego',
  ranking_second_title: 'Ránking',

}

export const home_translations = {
  content: {
    text_1: 'Bienvenido/a al despertar de la fuerza.',
    text_2: 'Participa en nuestro juego de preguntas para ver cuánto sabes de War Games y de Star Wars. Solo por participar, conseguirás 10 puntos extras en total para tu ranking dentro de la gamificación y, si quedas dentro del top 3 del ranking, ganarás un regalo exclusivo de Star Wars...',
    text_3: '¡Dale al play!',
    disclaimer: '*Podrás mejorar tu puntuación con un máximo de 3 partidas.'
  },
  link_text: 'Empezar',
}

export const welcome_translations = {
  title: '¿Cómo se juega?',
  instructions: [
    'Contesta correctamente a las 10 preguntas sobre War Games y Star Wars...¡Recuerda echar antes un vistazo a la nueva web para que no se te olvide nada!',
    'Tienes solo 30 segundos para contestar y, cuánto más rápido lo hagas, más puntos obtendrás. ¡El tiempo vuela!',
    'Si aciertas varias preguntas seguidas multiplicarás puntos que te llevarán a lo más alto del ranking.',
    'No olvides que puedes participar hasta 3 veces para mejorar tu puntuación. ',
    '¡Solo por participar consigues 10 puntos extras en total para War Games! Y si quedas entre los 3 primeros... ¡te llevarás un regalo exclusivo de Star Wars!'
  ],
  link_text: 'Siguiente'
}

export const sign_up_translations = {
  title: 'Loguéate con tu nombre y email de Paradigma y... ¡Empieza a jugar!',
  title3Times: '¡Gracias! Ya has participado 3 veces.',
  instructions: [
    'Elige tu nombre de usuario.',
    'Logueate con tu nombre de usuario y tu email*. ',
    '¡Empieza a jugar!',
  ],
  disclaimer: {
    text: '* Nos pondremos en contacto contigo a través de este email al final del evento si eres una de las personas ganadoras.'
  },
  label_username: 'Tu nombre *',
  label_email: 'Tu email *',
  button_text: 'Siguiente',
  error: 'Recuerda, solo puedes participar con tu email de Paradigma.'
}

export const game_translations = {
  main_subtitle: '¿Que ves en la imagen?'
}

export const answer_wait_screen_translations = {
  title: 'Procesando respuesta...',
}

export const time_out_translations = {
  title: 'Vaya... se acabó el tiempo.',
  content: [
    'No caigas en el lado oscuro...',
    '¡Utiliza tu fuerza!',
  ],
  content_last_question: '¡Gracias por participar! No te olvides de ver tu posición en el ranking'
}

export const right_translations = {
  title_strike: 'Has acertado {{streak}} preguntas seguidas.',
  title: '¡Has acertado, sigue así!',
  content_multiplier: 'Ahora tu puntuación por respuesta correcta se multiplica por {{multiplier}}.',
  content: '¡Estás que te sales!',
  content_last_question: '¡Gracias por participar! No te olvides de ver tu posición en el ranking'
}

export const wrong_translations = {
  title: 'Upss... ¡qué pena!',
  content: 'Esa no era la respuesta correcta, pero no desanimes.',
  content_last_question: '¡Gracias por participar! No te olvides de ver tu posición en el ranking'
}


export const finish_translations = {
  title: '¡Gracias por jugar, ¡Has conseguido {{points}} puntos en War games!',
  content: [
    {
      text: 'Recuerda, tienes un máximo de 3 partidas para mejorar tu puntuación en el ranking.'
    },
    {
      text: '¡Que la fuerza te acompañe!',
    },
  ],
}




