import {isMobile} from 'react-device-detect'
import {ReactComponent as LogoLong} from 'assets/icons/Logo-Long.svg'
import {ReactComponent as LogoShort} from 'assets/icons/Logo-Short.svg'


export const Logo  = () => {
  return (
    <>
      {isMobile ?
        <LogoShort/>
        :
        <LogoLong/>
      }
    </>
  )

}


